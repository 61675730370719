import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import api from "./utils/apiUtils";
import { flattenProperty } from "./utils/storeUtils";
import _ from "lodash";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  storage: window.sessionStorage,
  reducer: (state) => ({ auth: state.auth }),
});

const commonErrMsgs = (err) => {
  let errMsg;
  switch (err.response.status) {
    case 402:
      errMsg = "User does not have permission.";
      break;

    case 404:
      errMsg = "Resource not found.";
      break;

    case 500:
      errMsg = "Server error! Please see log.";
      break;
  }
};

const handleError = (err) => {
  console.error(commonErrMsgs(err));
  throw err;
};

const roomByName = (roomA, roomB) => {
  if (roomA.name < roomB.name) return -1;
  else if (roomA.name > roomB.name) return 1;
  else return 0;
};

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],

  state: {
    auth: {
      role: null,
      errMsg: null,
    },

    notifications: {
      status: null,
      msg: null,
    },

    settings: null,

    ccus: [],
    modes: [],
    rooms: [],
    roomGroups: [],
    devices: [],
    profiles: [],
    virtualSwitches: [],
    virtualSwitchMappings: [],
    dataMap: [],
    users: [],

    profilesBetweenDates: [],
    modesBetweenDates: [],
    activeMode: null,
  },

  mutations: {
    LOGIN__SUCCESS: function (state, role) {
      state.auth = { role, errMsg: "" };
    },

    LOGIN__FAIL: function (state, errMsg) {
      state.auth = { role: null, errMsg };
    },

    SET_CCUS__SUCCESS: function (state, ccus) {
      state.ccus = ccus;
    },

    SET_MODES__SUCCESS: function (state, modes) {
      state.modes = modes.map((mode) => flattenProperty(mode, "mode"));
    },

    SET_ACTIVE_MODE: function (state, mode) {
      state.activeMode = mode;
    },

    GET_ROOMS__SUCCESS: function (state, rooms) {
      state.rooms = rooms
        .map((room) => flattenProperty(room, "room"))
        .sort(roomByName);
    },

    GET_ROOM_GROUPS__SUCCESS: function (state, groups) {
      state.roomGroups = groups;
    },

    DELETE_ROOM__SUCCESS: function (state, roomId) {
      state.rooms = state.rooms.filter((room) => room.id !== roomId);
    },

    GET_DEVICES__SUCCESS: function (state, devices) {
      state.devices = devices;
    },

    GET_DATA_MAP__SUCCESS: function (state, dataMap) {
      state.dataMap = dataMap;
    },

    GET_SETTINGS__SUCCESS: function (state, settings) {
      state.settings = settings;
    },

    GET_PROFILES__SUCCESS: function (state, profiles) {
      state.profiles = profiles.map((p) => {
        return {
          id: p.profile.id,
          name: p.profile.name,
          colour: p.profile.colour,
          temps: p.temps,
        };
      });
    },

    SET_PROFILES_BETWEEN_DATES: function (state, profiles) {
      state.profilesBetweenDates = profiles;
    },

    SET_MODES_BETWEEN_DATES: function (state, modes) {
      state.modesBetweenDates = modes;
    },

    SET_VIRTUAL_SWITCHES: function (state, switches) {
      state.virtualSwitches = switches;
    },

    SET_VIRTUAL_SWITCH_MAPPINGS: function (state, switchMappings) {
      state.virtualSwitchMappings = switchMappings;
    },

    GET_USERS__SUCCESS: function (state, users) {
      console.log(users);
      state.users = users;
    },
  },

  actions: {
    LOGIN: function (store, loginDetails) {
      let commit = store.commit;

      return api
        .post("/api/user/login", loginDetails)
        .then((res) => {
          let authorizationToken = Cookies.get("authorization");
          const role = jwtDecode(authorizationToken.trim()).userRole;

          commit("LOGIN__SUCCESS", role);
          return res.data;
        })
        .catch((err) => {
          let errMsg;
          switch (err.response.status) {
            case 401:
              errMsg = "Login details incorrect!";
              commit("LOGIN__FAIL", errMsg);
              // TODO: NOTIFY USER
              break;

            default:
              console.error("LOGIN ERROR: ", err.response);
              errMsg = commonErrMsgs(err);
              commit("LOGIN__FAIL", errMsg);
              break;
          }

          store.commit("LOGIN_FAIL", errMsg);
          throw err;
        });
    },

    // CCUS
    GET_CCUS: function (store) {
      return api
        .get("/api/ccus")
        .then((res) => store.commit("SET_CCUS__SUCCESS", res.data))
        .catch((err) => {
          console.error(`[ERROR IN GET_CCUS]\n${commonErrMsgs(err)}`, err);
        });
    },

    SAVE_CCU: function (store, ccu) {
      return api
        .put("/api/ccu", ccu)
        .then((_) => store.dispatch("GET_CCUS"))
        .catch(handleError);
    },

    DELETE_CCU: function (store, ccuId) {
      return api
        .delete(`/api/ccu/${ccuId}`)
        .then((res) => store.dispatch("GET_CCUS"))
        .catch(handleError);
    },

    // MODES
    GET_MODES: function (store) {
      return api
        .get("/api/modes/withProfiles")
        .then((res) => {
          // attach pseudo-id to mode
          const modes = res.data.map((mode, index) => {
            return { ...mode, id: index };
          });

          store.commit("SET_MODES__SUCCESS", modes);
          return modes;
        })
        .catch(handleError);
    },

    GET_ACTIVE_MODE: function (store) {
      return api
        .get("/api/modes/active")
        .then((res) => store.commit("SET_ACTIVE_MODE", res.data))
        .catch(handleError);
    },

    SELECT_MODE: function (store, modeName) {
      return api
        .post(`/api/modes/select/${modeName}`, {})
        .then((res) => store.dispatch("GET_ACTIVE_MODE"))
        .catch(handleError);
    },

    CLEAR_MODES: function (store, dateRange) {
      return api
        .put("/api/modes/clearModes", dateRange)
        .then((res) => store.dispatch("GET_MODES"))
        .catch(handleError);
    },

    ADD_MODE_TO_DATE: function (store, modeToDate) {
      return api
        .put("/api/modes/addModeToDate", modeToDate)
        .then((res) => store.dispatch("GET_MODES"))
        .catch(handleError);
    },

    SAVE_MODE: function (store, modeWithProfilesToRooms) {
      return api
        .put("/api/modes", modeWithProfilesToRooms)
        .then((_) => store.dispatch("GET_MODES"))
        .catch(handleError);
    },

    GET_MODES_BETWEEN_DATES: function (store, { from, to }) {
      const body = {
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
      };
      return api
        .post(`api/modes/betweenDates`, body)
        .then((res) => {
          store.commit("SET_MODES_BETWEEN_DATES", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    DELETE_MODE: function (store, modeName) {
      return api
        .delete(`/api/modes/${modeName}`)
        .then((_) => store.dispatch("GET_MODES"))
        .catch(handleError);
    },

    // ROOMS
    GET_ROOMS: function (store) {
      return api
        .get("/api/rooms/withProfile")
        .then((res) => {
          store.commit("GET_ROOMS__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    GET_ROOM_GROUPS: function (store) {
      return api
        .get("/api/rooms/groupNames")
        .then((res) => {
          console.log(res);
          store.commit("GET_ROOM_GROUPS__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    SAVE_ROOM: function (store, roomWithDevices) {
      return api
        .put("/api/rooms/withDevices", roomWithDevices)
        .then((_) => store.dispatch("GET_ROOMS"))
        .then((_) => store.dispatch("GET_DEVICES"))
        .catch(handleError);
    },

    GET_PROFILES_BETWEEN_DATES: function (store, { roomId, from, to }) {
      const body = {
        roomId,
        from: from.format("YYYY-MM-DD"),
        to: to.format("YYYY-MM-DD"),
      };
      return api
        .post(`api/profiles/betweenDates`, body)
        .then((res) => {
          store.commit("SET_PROFILES_BETWEEN_DATES", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    CLEAR_PROFILES: function (store, dateRange) {
      api
        .put("/api/profiles/clearProfiles", dateRange)
        .then((res) => res.data)
        .catch(handleError);
    },

    ADD_PROFILE_TO_ROOM: function (store, profileToRoom) {
      api
        .put("api/profile/toRoom", profileToRoom)
        .then((res) => res.data)
        .catch(handleError);
    },

    // TODO: This function should get the updated room from the server after successfully updating
    SET_TEMPORARY_SET_POINT: function (store, { roomId, newTemp }) {
      return api
        .put("/api/rooms/temporarySetPoint", { roomId, newTemp })
        .then((_) => store.dispatch("GET_ROOMS", roomId))
        .catch(handleError);
    },

    // TODO: This function should get the updated room from the server after successfully updating
    SET_AUTO_MODE: function (store, { roomId, autoMode }) {
      return api
        .put("/api/rooms/autoMode", { roomId, autoMode })
        .then((_) =>
          store.commit("UPDATE_ROOM", { roomId, updates: { autoMode } })
        )
        .then((_) => store.dispatch("GET_ROOMS", roomId))
        .catch(handleError);
    },

    DELETE_ROOM: function (store, roomId) {
      return api
        .delete(`/api/rooms/${roomId}`)
        .then((res) => {
          store.commit("DELETE_ROOM__SUCCESS", roomId);
          return res.data;
        })
        .catch(handleError);
    },

    // DEVICES
    GET_DEVICES: function (store) {
      return api
        .get("/api/devices")
        .then((res) => {
          store.commit("GET_DEVICES__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    REFRESH_DEVICES: function (store) {
      return api.get(`/api/devices/refresh`).catch(handleError);
    },

    // DATA MAP
    GET_DATA_MAP: function (store) {
      return api
        .get("/api/data_map")
        .then((res) => {
          store.commit("GET_DATA_MAP__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    ADD_DATA_MAP: function (store, mapping) {
      return api
        .post("api/data_map", mapping)
        .then((res) => {
          store.dispatch("GET_DATA_MAP");
          store.dispatch("REFRESH_DEVICES");
          return res.dasta;
        })
        .catch(handleError);
    },

    DELETE_DATA_MAP: function (store, mapping) {
      return api
        .post(`/api/delete_data_map`, mapping)
        .then((res) => {
          store.dispatch("GET_DATA_MAP");
          store.dispatch("REFRESH_DEVICES");
          return res.data;
        })
        .catch(handleError);
    },

    // SETTINGS
    GET_SETTINGS: function (store) {
      return api
        .get("/api/settings")
        .then((res) => {
          store.commit("GET_SETTINGS__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    SAVE_SETTINGS: function (store, settings) {
      return api
        .put("/api/settings", settings)
        .then((_) => store.dispatch("GET_SETTINGS"))
        .catch(handleError);
    },

    // PROFILES
    GET_PROFILES: function (store) {
      return api
        .get("/api/profiles/withTemps")
        .then((res) => {
          store.commit("GET_PROFILES__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    SAVE_PROFILE: function (store, { profile, temps }) {
      return api
        .put("/api/profiles/withTemps", { profile, temps })
        .then((_) => store.dispatch("GET_PROFILES"))
        .catch(handleError);
    },

    DELETE_PROFILE: function (store, profileId) {
      return api
        .delete(`/api/profile/${profileId}`)
        .then((_) => store.dispatch("GET_PROFILES"))
        .catch(handleError);
    },

    // VIRTUAL SWITCHES
    GET_VIRTUAL_SWITCHES: function (store) {
      return api
        .get("/api/virtual_switches")
        .then((res) => store.commit("SET_VIRTUAL_SWITCHES", res.data))
        .catch(handleError);
    },

    GET_VIRTUAL_SWITCH_MAPPINGS: function (store) {
      return api
        .get("/api/virtual_switches/mappings")
        .then((res) => {
          const groupedMappings = _.groupBy(res.data, (i) => i.name);
          const mappings = Object.entries(groupedMappings).map(
            ([name, mappings]) => {
              return {
                name,
                deviceCcuId: mappings[0].deviceCcuId,
                deviceIseId: mappings[0].deviceIseId,
                deviceChannel: mappings[0].deviceChannel,
                switches: mappings.map((mapping) => {
                  return {
                    ccuId: mapping.virtualSwitchCcuId,
                    iseId: mapping.virtualSwitchIseId,
                  };
                }),
              };
            }
          );
          store.commit("SET_VIRTUAL_SWITCH_MAPPINGS", mappings);
        })
        .catch(handleError);
    },

    SAVE_VIRTUAL_SWITCH_MAPPING: function (store, mapping) {
      return api
        .post("/api/virtual_switches/mapping", mapping)
        .then((res) => store.dispatch("GET_VIRTUAL_SWITCH_MAPPINGS"))
        .catch(handleError);
    },

    DELETE_VIRTUAL_SWITCH_MAPPING: function (store, name) {
      return api
        .delete(`/api/virtual_switches/mapping/${name}`)
        .then((res) => store.dispatch("GET_VIRTUAL_SWITCH_MAPPINGS"))
        .catch(handleError);
    },

    // SERVICES
    REZLYNX_GET_ROOMS: function (store) {
      return api
        .get("/api/rezlynx/rooms")
        .then((_) => store.dispatch("GET_ROOMS"))
        .catch(handleError);
    },

    // USERS
    GET_USERS__SUCCESS: function (store) {},

    GET_USERS: function (store) {
      return api
        .get("/api/users")
        .then((res) => {
          store.commit("GET_USERS__SUCCESS", res.data);
          return res.data;
        })
        .catch(handleError);
    },

    SAVE_USER: function (store, user) {
      return api
        .put("/api/user", user)
        .then((res) => store.dispatch("GET_USERS"))
        .catch(handleError);
    },

    DELETE_USER: function (store, id) {
      return api
        .delete(`/api/user/${id}`)
        .then((res) => store.dispatch("GET_USERS"))
        .catch(handleError);
    },
  },
});
